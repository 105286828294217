#map_modal {
  .icon {
    width: 50px;
    height: 50px;
    margin: .5em;
  }

  #weather_details > div {
    &.heading_table {
      display: flex;
      flex-direction: column;

      & > div {
        background-color: #FFFFFF;
        color: #797979;
        width: 120px;
        height: 20px;
        font-size: 0.7em;
        text-align: left;
        padding-right: 8px;
        font-weight: 300;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        line-height: 9px;

        &.poi_title {
          font-size: 0.9em;
          color: #154194;
          font-weight: bold;
        }

        .short_label {
          display: none;
        }
      }
    }

    &.content_viewport {
      width: 100%;
      overflow: hidden;

      & > .content_table {
        display: flex;
        flex-direction: column;
        & > div {
          display: flex;

          & > div {
            padding: 0;
            font-size: 0.7em;
            height: 20px;
            width: 30px;
            line-height: 20px;
            text-align: center;
            box-sizing: border-box;
            flex: 0 0 auto;
          }
        }
      }
    }
  }

  .day_border {
    border-right: 1px solid #b3b3b3;
  }

  .day_border_left {
    border-left: 1px solid #b3b3b3;
  }

  .img-icon {
    max-width: 25px;
    height: auto;
  }

  .img-small {
    width: 2em;
    height: auto;
  }

  .th-border-top {
    border-width: 0.6px;
    border-top-style: solid;
    border-color: #797979;
  }

  .button {
    background-color: #a3a3a3;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.2em;
    margin: 3px -5px 3px 3px;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    padding: 0;
    position: relative;

    &:after {
      content: '+';
      position: absolute;
      left: 6px;
      top: 3px;
    }

    &:hover {
      cursor: pointer;
    }

    &.active:after {
      content: '-';
    }
  }

  .txt_smaller {
    font-size: 0.5em;
  }

  .night_background {
    background-color: #e5e5e5;
  }

  .table_day, div[data-hour='00h'] {
    border-left: 2px solid #999;
  }

  #weather_details {
    width: 100%;
    cursor: grab;
    display: flex;

    .details_hours {
      [data-hour='21h'], [data-hour='22h'], [data-hour='23h'], [data-hour='00h'], [data-hour='01h'], [data-hour='02h'],
      [data-hour='03h'], [data-hour='04h'], [data-hour='05h'], [data-hour='06h'] {
        background-color: #DEDEDE;
        display: block;
      }
    }
  }
  #weather_details.night_hidden {
      [data-hour='21h'], [data-hour='22h'], [data-hour='23h'], [data-hour='00h'], [data-hour='01h'], [data-hour='02h'],
      [data-hour='03h'], [data-hour='04h'], [data-hour='05h'], [data-hour='06h'] {
        display: none;
    }
    [data-hour='07h'] {
      border-left: 2px solid #999;
    }
  }



  // Swell values hidden for now
  .details_hs, .details_ds, .details_ps, .details_dsp {
    /*display: none !important;*/
  }

  .details_source {
    opacity: 0.3;
  }
}

