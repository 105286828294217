#map_container {
  position: relative;
  width: 100%;
  height: calc(100vh - 120px);
  overflow: hidden;
  min-height: 518px;
  #map {
    .leaflet-control-container {
      .leaflet-control {
        border: none;

        a {
          color: #00A6E7;
          width: 32px;
          height: 32px;
          line-height: 32px;

          &:first-child {
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          }

          &:last-child {
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          }
        }
      }

      .leaflet-top.leaflet-right {
        top: 10px;
        right: 13px;
      }

      .leaflet-bottom.leaflet-right {
        bottom: 50%;
        right: 13px;
      }
    }
  }
}

#map_menu_list {
  position: absolute;
  z-index: 99;
  top: 10px;
  left: 10px;
}

ul#map_menu {
  list-style: none;
  margin: 0;
  padding: 10px;
  height: 46px;
  position: relative;

  li:not(:first-child) {
    display: none;
  }
  .legend {
    display: none;
  }
}

ul#map_menu.expanded {
  li {
    display: flex;
  }
  .legend {
    display: flex;
    flex-direction: column;
    color: white;
    align-items: center;
    position: absolute;
    left: -4px;
    text-align: center;

    &.rt {
      top: 240px;
      line-height: 20px;
    }

    &.fc {
      top: 74px;
      line-height: 18px;
    }
  }
}

.layer_menu li {
  line-height: 1;
  display: flex;
  align-items: center;
  margin-bottom: 0;
  padding: 3px;
  color: white;
  font-size: 11px;
}
ul#map_menu li {
  &:first-child {
    padding-top: 0;
    position: absolute;
    width: 240px;
  }

  &:nth-child(2) {
    margin-top: 56px;
  }
}

#map_menu button#toggle_map_menu {
  line-height: 32px;
  background-color: white;
  color: #00A6EE;
  border-radius: 25px;
  font-size: 22px;
  width: 240px;
  height: 52px;
  margin-left: -10px;
}

#map_favorite {
  position: absolute;
  top: 10px;
  left: 263px;
  z-index: 6;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  button {
    color: white;
    cursor: pointer;
    background-color: #00A6EE ;
    border-color: transparent;
    border-radius: 50%;
    padding: 0;
    height: 52px;
    width: 52px;
    font-size: 9px;
    line-height: 8px;
    span{
      line-height: 14px;
    }
  }
}

.no-favorite{
  background-color: rgb(255, 1, 158) !important;
}

#map_favorite.active, #map_favorite:hover {
  button{
    box-shadow: 0 0 0 3px #154194;
  }
}

#map_menu button#toggle_map_menu img {
  width: 170px;
  margin: 0 5px;
}

#map_menu #toggle_map_menu > i:nth-child(2), #map_menu.expanded #toggle_map_menu > i:first-child {
  display: none;
}

#map_menu.expanded #toggle_map_menu > i:nth-child(2) {
  display: initial;
}

.layer_menu button {
  height: 32px;
  width: 32px;
  text-align: center;
  cursor: pointer;
  border: none;
  padding: 0;
  background-color: transparent;
}

.layer_menu button img {
  width: 32px;
  height: auto;
}

#map_menu button {
  margin-right: 10px;
}

#map_bg_menu button {
  margin-left: 10px;
}

#map_bg_menu{
  #legend{
    margin-top: 20px;
  }
  .legend{
    margin-top: 20px;
  }
}
.layer_menu span {
  display: none;
}

.layer_menu button:hover + span, .layer_menu button.active + span, #legend + span {
  display: initial;
}

.layer_menu button.active, .layer_menu button:hover {
  box-shadow: 0 0 0 3px #154194;
  border-radius: 50%;
}

#map_bg_menu button {
  order: 2;
}

#map_bg_menu li {
  justify-content: flex-end;
}

#map_controls {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  border-radius: 4px;
  overflow: hidden;
}

#map_controls button, #location_controls button {
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  background-color: white;
  color: #00A6EE;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

#map_controls button:hover, #location_controls button:hover, #location_controls button.active {
  background-color: #00A6EE;
  color: white;
}

#map_controls hr {
  margin: 0 0 -1px 0;
  border-top: 1px solid #00A6EE;
}

#location_controls {
  position: absolute;
  top: 120px;
  right: 20px;
  z-index: 99;
  border-radius: 4px;
  overflow: hidden;
}

#amp_list{
  position: absolute;
  z-index: 99;
  top: 144px;
  right: 20px;
}

ul#amp_menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#amp_menu  span {
  margin-right: 10px;
}

#amp_menu button {
  order: 2;
}

#amp_menu li {
  justify-content: flex-end;
}

#map_bg_list {
  position: absolute;
  z-index: 99;
  bottom: 14vh;
  right: 20px;
}

ul#map_bg_menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#timeline_commands, #timeline_content, #mobile_timeline {
  position: absolute;
  z-index: 99;
  left: 20px;
  right: 20px;
}

#timeline_commands {
  bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 420px;
  padding: 20px 0 12px 0;
}

#timeline_commands > * {
  display: flex;
  flex: 1 0 auto;
}

#timeline_commands button {
  border: 1px solid white;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: transparent;
  color: white;
  padding: 5px;
  font-size: 16px;
  margin-right: 8px;
}

#timeline_commands button:hover {
  cursor: pointer;
  background-color: #00A6E7;
}

#timeline_commands #timeline_ts {
  display: block;
  text-align: center;
  margin: 0 15px 0 0;
  line-height: 1.1;
  color: white;
}

#timeline_commands #timeline_date {
  text-transform: uppercase;
}

#timeline_content {
  bottom: 40px;
  justify-content: space-between;
  align-items: center;
}

#timeline_content:after {
  position: absolute;
  top: 4px;
  content: '';
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: -1;
}

#timeline_content .tl_item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  opacity: 0;
}

#timeline_content .tl_item[data-rt] {
  background-color: #FFF;
  opacity: 1;
}

#timeline_content .tl_item[data-fc] {
  background-color: #00A6E7;
  opacity: 1;
  position: relative;
}

#timeline_content .tl_item[data-rt][data-fc] {
  border: 2px solid #FFF;
  background-color: #00A6E7;
  width: 5px;
  height: 5px;
}

#timeline_content .tl_item[data-fc]:after {
  content: attr(data-fc);
  position: absolute;
  top: 8px;
  left: -6px;
  font-size: 14px;
  color: white;
}

#mobile_timeline {
  bottom: 24px;
}

#mobile_timeline .rangeSlider__fill {
  background: none;
  box-shadow: none;
}

#mobile_timeline .rangeSlider__buffer {
  height: 10px;
  top: 5px;
  background: white;
}

#mobile_timeline .rangeSlider {
  background: #00A6E7;
  border: none;
  box-shadow: none;
}

img.pointer_direction {
  width: 22px;
}

#data_info {
  display: none;
  position: absolute;
  z-index: 99;
  right: 20px;
  bottom: 48px;
  padding: 0 0 12px 0;
  width: 320px;
  justify-content: end;
  color: white;

  & > div:not(:first-child) {
    & > p {
      padding-left: 10px;
      border-left: 1px solid white;
    }
  }

  & > div:first-child:not(:last-child) {
    padding-right: 10px;
  }

  p {
    margin: 0;
    font-size: 11px;
    line-height: 1.1;

    em {
      font-size: 10px;
    }
  }
}

#map_container.wind_fc, #map_container.waves_fc, #map_container.weather_fc,  #map_container.wind, #map_container.amp {
  #data_info {
    display: flex;
  }
}

#timeline_content .tl_item[data-rt]:hover, #timeline_content .tl_item[data-fc]:hover {
  cursor: pointer;
  background-color: #F7A600;
}

#map_container #timeline_content .tl_item.active, #map_container #timeline_content .tl_item.active:hover {
  background-color: red;
}

#static_canvas, #live_canvas, #forecasts, #measures, #meteogramme_wrapper, #map {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

#coast_line {
  background-color: #0F73A9;
  z-index: 3;
  display: none;
}

#map_container.wind_fc.rendered #map {
  background-color: transparent;
}

#map_container.wind_fc.rendered #coast_line, #map_container.waves_fc.rendered #coast_line {
  background: none;
}

#map_container.waves_fc.rendered #live_canvas {
  z-index: 2;
}

#static_canvas {
  z-index: 2;
}

.fc_colors {
  filter: url(#bgFilter);
}

#map {
  z-index: 6;
  width: 100%;
  height: 100%;
  background-color: #0F73A9;
}

#particles_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 101;
}

#waves_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

#map .mnt_layer {
  z-index: 201 !important;
}

#forecasts {
  z-index: 2;
}

#measures {
  z-index: 5;
  cursor: pointer;
}

#map_legend {
  filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.8));
  display: none;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 999;
  background-color: white;
  padding: 20px 10px;
  width: 280px;
  font-size: 10px;
  color: #154194;
}

#map_legend.active {
  display: block;
}

#map_legend h4 {
  margin: 0 0 10px 0;
  text-transform: uppercase;
  text-align: center;
}

#map_legend img {
  width: 12px;
  transform: rotate(-90deg)
}

#map_legend table {
  text-align: center;
  border-collapse: inherit;
  table-layout: fixed;
  color: #154194;
  margin: auto;
  width: 100%;
  border-spacing: 10px 0;
}

#map_legend thead tr th {
  color: #154194;
  text-transform: uppercase;
  text-align: center;
}

#map_legend table th, #map_legend table td {
  //padding: 2px;
}

#map_legend tbody tr:nth-child(6),
#map_legend tbody tr:nth-child(7),
#map_legend tbody tr:nth-child(8),
#map_legend tbody tr:nth-child(9) {
  color: white;
}

#map_legend table + div {
  display: flex;
  padding: 10px;
  justify-content: space-around;
}
#map_legend .legend_close{
  display: flex;
  justify-content: flex-end;
  padding: 10px 13px 0 0;
}

#map_legend .legend_close button:hover {
  cursor: pointer;
}

#meteogramme_wrapper {
  display: none;
  z-index: 5;
}

#meteogramme_wrapper.active {
  display: block;
}

#measures .fcc rect {
  fill-opacity: 0;
  cursor: pointer;
}

//#map_click_details circle {
//  fill: #0F73A9;
//  stroke: #154194;
//}
//
//#map_click_details p {
//  text-align: center;
//  margin: 0;
//  border: 1px solid #154194;
//  border-radius: 0 10px 10px;
//  font-size: 12px;
//}



.wind_details body, .weather_poi body,  .webcam_spot body, .temp_sensor body, .location_spot body, .tide_spot body ,.amp_spot body,
#map_click_details body, .wave_sensor body, .water_temp_sensor body {
  background-color: transparent;
  position: static;
}

.wind_details p, .temp_sensor p, .water_temp_sensor p {
  margin: 0;
  font-size: 12px;
  color: #154194;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
}

.wind_details p > * {
  width: 50%;
  text-align: center;
  display: inline-block;
  padding: 3px 4px;
}

.wind_details p > :first-child {
  padding-left: 6px;
}

.wind_details p > :last-child {
  padding-right: 6px;
}

.wind_sensor {
  fill: white;
  color: white;

  .devent {
    fill: #A4B4CD;
  }
}

.wind_sensor .sensor_name {
  transform: translate(-10px, 26px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
}

.wave_sensor .sensor_name {
  transform: translate(-10px, 36px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  fill: white;
}

.water_temp_sensor .sensor_name {
  transform: translate(36px, 54px);
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  fill: white;
}

#map_container.wind_fc .wind_details p {
  border: 1px solid  #154194;
}

#map_container.wind_fc .wind_sensor {
  stroke: transparent;
}

.temp_sensor p, .water_temp_sensor p {
  background-color: white;
  padding: 3px 4px;
  justify-content: center;
}

.water_temp_sensor p {
  color: #00A6E7;
  border: 1px solid #00A6E7;
}

.hide_inactive .weather_poi:not(.poi-active) {
  display: none;
}

.weather_poi .poi_icon {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-size: 16px;
  color: white;
  font-weight: 500;
  padding: 16px 2px 2px 2px;
  text-decoration: none;
}


.poi-active {
  body {
    background-color: rgba(255, 255, 255, 0.6);
    border-radius: 4px;

    p {
      display: block;
      width: 100%;
      line-height: 1;
      font-size: 10px;
      color: #154194;
      text-align: center;
      margin: -10px 0 10px 0;
      padding: 0 0 10px 0;
    }
    span{
      color: #154194;
    }
  }

}

.weather_poi img {
  width: 36px;
}

.weather_poi p {
  width: 100%;
  line-height: 1;
  font-size: 10px;
  color: #154194;
  text-align: center;
  display: none;
  margin: -10px 0 10px 0;
  padding: 0 0 10px 0;
}

.weather_poi:hover p, .weather_poi.active p {
  display: block;
}

.weather_poi:hover body, .weather_poi.active body {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 4px;
}

.weather_poi.active .poi_icon, .weather_poi.active p {
  color: #154194;
}

.wave_sensor .waves .capteur_nom {
  display: none;
}

.wave_sensor .swell_data {
  display: flex;
  height: 32px;
  align-items: center;
  text-decoration: none;
  background-color: transparent;

  .swell_height, .swell_period, .swell_temp {
    background-color: white;
    padding: 4px;
    line-height: 12px;
    color: #154194;
    font-size: 12px;
  }

  .swell_height {
    border-radius: 12px 0 0 12px;
    padding-left: 8px;
  }

  .swell_temp {
    border-radius: 0 12px 12px 0;
    padding-right: 8px;
  }

  & > div span {
    font-size: 8px;
    display: block;
  }
}

.wave_sensor img {
  width: 32px;
}

.webcam_spot img {
  width: 28px;
  background-color: white;
  border: 1px solid #00A6EE;
  border-radius: 10px;
}

.tide_spot img {
  width: 28px;
  background-color: #00A6EE;
  border: 1px solid white;
  border-radius: 10px;
}

.amp_spot img {
  width: 28px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
}

.amp_zone{
  fill: rgba(224,87,247, 0.5);
  stroke: rgba(224,87,247, 1);
}
.location_spot i {
  font-size: 32px;
  width: 32px;
  height: 32px;
  color: #154194;
}

#shom_wrapper {
  width: 675px;
  height: 910px;
  border: none;
  -ms-overflow-style: none;
  overflow: auto;
}

#map_wrapper {
  .leaflet-popup-content-wrapper {
    border-radius: 10px;
    padding: 0;
    overflow: hidden;
    border: 2px solid white;

    .leaflet-popup-content {
      margin: 0 !important;

      & > p {
        margin: 0;
        padding: 3px 8px;
      }

      .pointer_info {
        width: 85px;
      }
    }
  }

  .leaflet-popup-tip {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
    padding: 0;
    width: 2px;
  }

  a.leaflet-popup-close-button {
    padding-top: 6px;
    padding-left: 4px;
    color: white;
  }

  .leaflet-popup-content-wrapper, .leaflet-popup-tip {
    box-shadow: none;
  }
}
