@media screen and (max-width: 839px) {
  #map_modal[data-modal='poi'] .modal__container {
    padding-bottom: 0;
    width: calc(100vw - 40px);
    filter: drop-shadow(0 0 0.3rem rgba(0, 0, 0, 0.6));

    & > #map_modal_content {
      width: calc(100vw - 100px);
      border-radius: 4px;
    }
  }
  .modal_button_group {
    top: unset;
    right: 15px;
    bottom: 1rem;
    margin: auto;

    button {
      padding: 10px;
    }
  }
}

@media screen and (min-width: 769px) {
  #timeline_content {
    display: flex;
  }

  #mobile_timeline {
    display: none;
  }

  #mobile_nav {
    display: none;
  }
}

@media screen and (max-width: 768px) {

  #timeline_content {
    display: none;
  }

  #map_bg_menu li > span:last-child {
    display: none;
  }

  #site_header {
    display: none;
  }

  #map_container {
    height: calc(100vh - 150px) !important;
    min-height: 525px !important;
  }

  #timeline_commands {
    bottom: 56px !important;
  }
  #map_modal {
    .radio-container {
      justify-content: start;

      &_item {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }
  #map_menu span {
    display: none !important;
  }

  #footer-partner #footer-columns {
    width: 100%;

    .img_logo img {
      height: 36px;
    }
  }

  .amp_modal{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    .amp_photo{
      width: fit-content;
      padding: 1rem;
      img{
        max-width: 100%;
        height: auto;
      }
      p{
        font-size: 9px;
      }
    }

    .amp_description{
      width: fit-content;
      padding: 1rem;
    }
  }
}

@media screen and (max-width: 420px) {

  #timeline_commands {
    #step_backward, #tl_step_forward {
      display: none;
    }
  }

  #map_modal {
    .modal__header, #charts_actions, aside {
      flex-direction: column;
    }

    .radio-container {
      justify-content: start;

      &_item {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }

    #map_modal_content > aside > * {
      max-width: 100%;
    }

    #weather_details > div {
      &.heading_table > div {
        width: 70px !important;

        span.short_label {
          display: inline;

          & + span {
            display: none;
          }
        }
      }
    }
  }

  #map_wrapper {
    #map_container {
      .leaflet-top.leaflet-right {
        display: none;
      }

      .leaflet-bottom.leaflet-right {
        top: 20px;
        right: 10px;
      }

      #map_bg_list {
        top: 137px;
      }

      #amp_list{
        top: 72px;
      }

      #data_info {
        left: 20px;
        bottom: 105px;
        justify-content: center;
        width: 92%;

        & > p {
          br {
            display: none;
          }

          em {
            padding-left: 5px;
          }
        }
      }
    }
  }

  #news_modal {
    .modal__container {
      padding: 10px 10px 0 10px;
      max-width: calc(100vw - 20px);
    }

    #news_modal_content {
      button {
        height: 10px;
        width: 10px;
        margin: 10px 3px 0 3px;
      }
    }

    #news_slides {
      .tns-item {
        & > div {
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}
