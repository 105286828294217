@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Regular.woff2') format('woff2'),
  url('../fonts/Montserrat-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat-Bold.woff2') format('woff2'),
  url('../fonts/Montserrat-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

body {
  font-family: "Montserrat", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: white;
}

a:focus, button:focus {
  outline: none;
}

.hidden {
  display: none;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #154194;
  font-weight: 300;
}

header#site_header {
  position: fixed;
  background-color: transparent;
  //width: 60%;
  height: 60px;
  top: 0;
  right: 60px;
  z-index: 101;
}

header#site_header nav {
  display: flex;
  height: 100%;
  padding: 10px;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

header#site_header nav img {
  width: 200px;
}

header#site_header nav > * {
  padding: 16px 20px;
}

header#site_header nav a, #mobile_nav nav a {
  text-decoration: none;
  text-transform: uppercase;
  color: #02ACF1;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 4px;
}

header#site_header nav a:hover, #mobile_nav nav a:hover {
  background-color: #154194;
  color: white;
  transition: background-color 0.5s, color 0.5s;
}

#mobile_nav nav {
  margin: 20px auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#footer {
  padding: 20px;
}

#partners a {
  display: inline-block;
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
}

#footer-partner {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 20px;
}

#footer-columns {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}

.img_logo img {
  height: 60px;
  max-width: 150px;
}

#footer hr {
  width: 240px;
  border: none;
  margin: 10px auto;
  height: 2px;
  background-color: #39A9E0;
}

#footer_info {
  margin-bottom: 40px;
  font-size: smaller;
}

#footer_info p {
  margin-top: 15px;
  text-align: center;
}

#footer_info p a {
  color: #000000;
  text-decoration: none;
}

#wm_links {
  max-width: 960px;
  padding: 20px;
  margin: 120px auto;

  h1 {
    color: #154194;
  }

  a {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    color: black;

    img {
      width: 60px;
      height: auto;
      margin-right: 20px;
    }
  }
}

#new_contact {
  margin: 120px auto 40px auto;
  width: 600px;
  padding: 1em;
  border: 1px solid #39A9E0;
  border-radius: 1em;

  & > div + div {
    margin-top: 1em;
  }

  label {
    display: inline-block;
    width: 90px;
    text-align: right;
  }

  input, textarea {
    font: 1.1em sans-serif;
    width: 400px;
    box-sizing: border-box;
    border: 1px solid #999;
    border-radius: 4px;
    padding: 4px 8px;
  }

  input:focus, textarea:focus {
    border-color: #000;
  }

  textarea {
    vertical-align: top;
    height: 20em;
  }

  .form_button {
    background: #39A9E0;
    border: 4px solid #fff;
    border-radius: 10px;
    color: #fff;
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin: 10px auto;
    padding: 1em 3em;
    position: relative;
    text-transform: uppercase;
  }

  h3 {
    color: #39A9E0;
    font-weight: bold;
    font-style: normal;
    text-transform: uppercase;
    text-align: center;
  }

  .submit_msg {
    text-align: center;
  }
}

.logo_contact {
  width: 220px;
  height: auto;
  margin-left: -170px;
  margin-top: -40px;
}

